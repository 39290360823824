export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは6月より%d点上がりました。暑さに負けず、順調ですね！<br>\n8月のテーマは、<b>❝不眠、貧血、冷房病etc.. 夏の不調にご用心❞​</b>   です。高温多湿なこの季節は、様々な不調が出やすいです。疲れやだるさなど体の変化にいち早く気が付き、自分に合った対策やケアをしていきましょう。&wellで配信されるコンテンツも上手くご活用ください。\n\n",
      "down": "生活習慣スコアは6月より%d点下がりました。できることから気を付けてみましょう。<br>\n8月のテーマは、<b>❝不眠、貧血、冷房病etc.. 夏の不調にご用心❞​</b>​   です。高温多湿なこの季節は、様々な不調が出やすいです。疲れやだるさなど体の変化にいち早く気が付き、自分に合った対策やケアをしていきましょう。&wellで配信されるコンテンツも上手くご活用ください。\n\n",
      "equal100": "生活習慣スコアは6月に引き続き%満点%です。大変素晴らしいです！<br>\n8月のテーマは、<b>❝不眠、貧血、冷房病etc.. 夏の不調にご用心❞​​</b>   です。高温多湿なこの季節は、様々な不調が出やすいです。疲れやだるさなど体の変化にいち早く気が付き、自分に合った対策やケアをしていきましょう。&wellで配信されるコンテンツも上手くご活用ください。\n\n",
      "equal": "生活習慣スコアは6月と同じ点数です。<br>\n8月のテーマは、<b>❝不眠、貧血、冷房病etc.. 夏の不調にご用心❞​</b>​   です。高温多湿なこの季節は、様々な不調が出やすいです。疲れやだるさなど体の変化にいち早く気が付き、自分に合った対策やケアをしていきましょう。&wellで配信されるコンテンツも上手くご活用ください。\n\n"
    },
    "steps": {
      "up": "歩数スコアは6月より%d点上がりました。<br>\n暑い季節も歩数が増やせています。さすがです！<b>Walk チーム対抗戦 2023 2nd​</b>のエントリ―が8月8日から始まります！お楽しみに♪\n\n",
      "down": "歩数スコアは6月より%d点下がりました<br>\n<b>Walk チーム対抗戦 2023 2nd​</b>のエントリ―が8月8日から始まります！お楽しみに♪\n\n",
      "equal": "歩数スコアは6月と同じ点数です。<br>\n<b>Walk チーム対抗戦 2023 2nd​</b>のエントリ―が8月8日から始まります！お楽しみに♪\n\n"
    },
    "exercise": {
      "up": "運動のスコアは6月より%d点上がりました。<br>\nストレッチは身体のだるさや冷えむくみ対策にもつながります。早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。\n\n",
      "down": "運動のスコアは6月より%d点下がりました。<br>\nストレッチは身体のだるさや冷えむくみ対策にもつながります。早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。\n\n",
      "equal": "運動のスコアは6月と同じ点数です。<br>\nストレッチは身体のだるさや冷えむくみ対策にもつながります。早朝や就寝前など涼しい時間の方が気持ちよく身体を動かせますよ。\n\n"
    },
    "meal": {
      "up": "食事のスコアは6月より%d点上がりました。<br>\n水分補給する際は、1度に大量の水を飲むのではなく、コップ1杯分（200ml）の水を何度かに分けて飲むようにしましょう。\n\n",
      "down": "食事のスコアは6月より%d点下がりました。<br>\n水分補給する際は、1度に大量の水を飲むのではなく、コップ1杯分（200ml）の水を何度かに分けて飲むようにしましょう。\n\n",
      "equal": "食事のスコアは6月と同じ点数です。<br>\n水分補給する際は、1度に大量の水を飲むのではなく、コップ1杯分（200ml）の水を何度かに分けて飲むようにしましょう。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは6月より%d点上がりました。<br>\nお酒を飲んだときはビタミンB1が不足しやすいです。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。\n\n",
      "down": "飲酒のスコアは6月より%d点下がりました。<br>\nお酒を飲んだときはビタミンB1が不足しやすいです。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。\n\n",
      "equal": "飲酒のスコアは6月と同じ点数です。<br>\nお酒を飲んだときはビタミンB1が不足しやすいです。冷奴や枝豆を食べて、ビタミンB1をとりましょう。豚肉やレバーにも多く含まれています。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは6月より%d点上がりました。<br>\n日中の眠気を感じる日が少なくなっていますね。この調子です。寝る前にリラックスして心身の緊張をほぐすと、睡眠の質がさらに良くなりますよ。\n\n",
      "down": "睡眠のスコアは6月より%d点下がりました。<br>\n快適な環境づくりや、刺激物を避けることも快眠につながります。<a href=\"https://go.andwell.jp/library/0e39be14-e80c-11ed-b85c-06528b823331\">こちらのコラム</a>がオススメです。\n\n",
      "equal": "睡眠のスコアは6月と同じ点数です。<br>快適な環境づくりや、刺激物を避けることも快眠につながります。<a href=\"https://go.andwell.jp/library/0e39be14-e80c-11ed-b85c-06528b823331\">こちらのコラム</a>がオススメです。"
    },
    "stress": {
      "up": "ストレスのスコアは6月より%d点上がりました。<br>\n急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度​</b>と言われています。\n\n",
      "down": "ストレスのスコアは6月より%d点下がりました。<br>\n急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度​</b>と言われています。\n\n",
      "equal": "ストレスのスコアは6月と同じ点数です。<br>\n急激な温度変化は心身ともに負担になります。人が室内で過ごせる快適な温度は、一般的に<b>夏場は25～28度​</b>と言われています。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "The Lifestyle Score went up by %d points since June. We are beating the heat!<br>The theme for August is <b>\"Beware of Summer Diseases such as Insomnia, Anemia, Legionnaires' Disease, etc.\"</b> The high temperature and humidity of this season can cause a variety of ailments. Be the first to notice changes in your body, such as tiredness and lethargy, and take appropriate measures and care for yourself. Please also make good use of the products offered by &well.",

      "down": "The Lifestyle Score went down by %d points since June. Let's try a bit harder.<br>The theme for August is <b>\"Beware of Summer Diseases such as Insomnia, Anemia, Legionnaires' Disease, etc.\"</b> The high temperature and humidity of this season can cause a variety of ailments. Be the first to notice changes in your body, such as tiredness and lethargy, and take appropriate measures and care for yourself. Please also make good use of the products offered by &well.",

      "equal100": "The Lifestyle Score is perfect, just as it was in June. Great work!<br>The theme for August is <b>\"Beware of Summer Diseases such as Insomnia, Anemia, Legionnaires' Disease, etc.\"</b> The high temperature and humidity of this season can cause a variety of ailments. Be the first to notice changes in your body, such as tiredness and lethargy, and take appropriate measures and care for yourself. Please also make good use of the products offered by &well.",

      "equal": "The Lifestyle Score is the same as it was in June.<br>The theme for August is <b>\"Beware of Summer Diseases such as Insomnia, Anemia, Legionnaires' Disease, etc.\"</b> The high temperature and humidity of this season can cause a variety of ailments. Be the first to notice changes in your body, such as tiredness and lethargy, and take appropriate measures and care for yourself. Please also make good use of the products offered by &well."
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since June.<br>\nWe can increase our steps even in the hottest season. Entries for the <b>Team Walk Competition 2023 2nd</b> will open on August 8! Enjoy!\n\n",
      "down": "Your steps score has gone down %d pts since June. <br>\nEntries for the <b>Team Walk Competition 2023 2nd</b> will open on August 8! Enjoy!\n\n",
      "equal": "Your steps score is the same as June. <br>\nEntries for the <b>Team Walk Competition 2023 2nd</b> will open on August 8! Enjoy!\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since June.<br>\nStretching also helps prevent the body from getting sluggish or swelling from the cold. You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.\n\n",
      "down": "Your exercise score has gone down %d pts since June.<br>\nStretching also helps prevent the body from getting sluggish or swelling from the cold. You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.\n\n",
      "equal": "Your exercise score is the same as June.<br>\nStretching also helps prevent the body from getting sluggish or swelling from the cold. You can move your body more comfortably when it's cool, such as early in the morning or before you go to bed.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since June.<br>\nWhen hydrating, drink one glass (200 ml) of water in several sips rather than a large amount all at once.\n\n",
      "down": "Your food score has gone down %d pts since June.<br>\nWhen hydrating, drink one glass (200 ml) of water in several sips rather than a large amount all at once.\n\n",
      "equal": "Your food score is the same as June.<br>\nWhen hydrating, drink one glass (200 ml) of water in several sips rather than a large amount all at once.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since June.<br>\nYou tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.\n\n",
      "down": "Your alcohol score has gone down %d pts since June.<br>\nYou tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.\n\n",
      "equal": "Your alcohol score is the same as June.<br>\nYou tend to lack vitamin B1 when you drink alcohol. Get more vitamin B1 by eating cold tofu or edamame. There's a lot of it in pork and liver, too.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since June.<br>\nYou are feeling less and less sleepy during the day. Keep it up! If you relax and unwind your mind and body before bed, your quality of your sleep will be even better.\n\n",
      "down": "Your sleep score has gone down %d pts since June.<br>\nMore and more often you are feeling sleepy during the day. Creating a comfortable environment and avoiding stimulants can also help you sleep better. We recommend <a href=\"https://go.andwell.jp/library/0e39be14-e80c-11ed-b85c-06528b823331\">this column</a>.\n\n",
      "equal": "Your sleep score is the same as June.<br>\n Creating a comfortable environment and avoiding stimulants can also help you sleep better. We recommend <a href=\"https://go.andwell.jp/library/0e39be14-e80c-11ed-b85c-06528b823331\">this column</a>.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since June.<br>\nSudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.\n\n",
      "down": "Your stress score has gone down %d pts since June.<br>\nSudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.\n\n",
      "equal": "Your stress score is the same as June.<br>\nSudden changes in temperature put stress on the body and mind. People are typically comfortable with a room temperature of <b>about 25 to 28 degrees in the summer</b>.\n\n"
    }
  }
}